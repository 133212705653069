import React, { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { usePreferencesState } from '@module/CustomerPreferences'
import { IMethodsType } from '@module/CustomerPreferences/services'
import { UIFieldset, UIFieldsetSpan, UIRadio, UIRadioGroup } from '@ui/components'
import { useIntl } from 'react-intl'

export interface DeliveryMethodProps {
    methods: IMethodsType[]
}

export const DeliveryMethods = (
    { methods }: DeliveryMethodProps
) => {
    const { register, setValue } = useFormContext()
    const { setCurrentShippingMethodCode } = usePreferencesState()
    const { isLoading } = usePreferencesState()
    const { formatMessage } = useIntl()

    if (!methods) return null

    useEffect(() => {
        const activeMethod = methods.find(method => method.active)
        if (activeMethod) {
            setCurrentShippingMethodCode(activeMethod.code)
            setValue('checkout.default_delivery_method', activeMethod.code)
        }
    }, [])

    return (
        <UIFieldset legend={formatMessage({
            id: 'deliveryMethods.defaultMethod',
            defaultMessage: 'Default Delivery Method'
        })}>
            <UIFieldsetSpan>
                <UIRadioGroup>
                    {methods.map(method =>
                        <UIRadio register={register('checkout.default_delivery_method')}
                                 name={'checkout.default_delivery_method'}
                                 disabled={isLoading}
                                 onChange={e => setCurrentShippingMethodCode(e.target.value)}
                                 value={method.code}
                                 label={method.title}/>
                    )}
                </UIRadioGroup>
            </UIFieldsetSpan>
        </UIFieldset>
    )
}
