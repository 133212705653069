import React, { FC, useEffect, useState } from 'react'
import { IPaymentMethodsType } from '@module/CustomerPreferences/services'
import { usePreferencesState } from '@module/CustomerPreferences'
import { useFormContext } from 'react-hook-form'
import { UIFieldset, UIFieldsetSpan, UIRadio, UIRadioGroup } from '@ui/components'
import { useIntl } from 'react-intl'

export interface PaymentMethodProps {
    methods: IPaymentMethodsType[]
}

export const PaymentMethods = (
    { methods }: PaymentMethodProps
) => {
    const { register, setValue } = useFormContext()
    const [availableMethods, setAvailableMethods] = useState<Array<any>>([])
    const { currentShippingMethodCode, isLoading } = usePreferencesState()
    const { formatMessage } = useIntl()

    if (!methods) return null

    useEffect(() => {
        const deliveryMethod = currentShippingMethodCode

        const availableMethods = methods.filter(method => {
            return !method.allowed || method.allowed.includes(deliveryMethod)
        })

        setAvailableMethods(availableMethods)

        const activeMethod = availableMethods.find(method => method.active)
        setValue('checkout.default_payment_method', activeMethod
            ? activeMethod.code
            : availableMethods[0]?.code || null
        )
    }, [currentShippingMethodCode])

    return (
        <UIFieldset legend={formatMessage({
            id: 'paymentMethods.defaultMethod',
            defaultMessage: 'Default Payment Method'
        })}>
            <UIFieldsetSpan>
                <UIRadioGroup>
                    {availableMethods.map(method =>
                        <UIRadio register={register('checkout.default_payment_method')}
                                 name={'checkout.default_payment_method'}
                                 value={method.code}
                                 disabled={isLoading}
                                 label={method.title}/>
                    )}
                </UIRadioGroup>
            </UIFieldsetSpan>
        </UIFieldset>
    )
}
