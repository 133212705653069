import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { IMethodsType, IPaymentMethodsType } from '../../services'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { request } from '../../../../models/graphql/request'
import { preferencesQuery, savePreferencesQuery } from '../../queries'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useYup } from '@module/Yup'
import { useIntl } from 'react-intl'
import intl from '../../components/Preferences/intl'
import { GraphQlResponse } from '@model/api'
import { useCustomer } from '@module/Customer'

export type GraphQl<Type> = {
    data: Type
}

export interface CustomerPreferencesInterface {
    inactiveCustomers: {
        enabled: boolean
        isInactiveCustomersEnabled: boolean
        offset: number
    };
    checkout?: {
        receive_order_confirmation: boolean,
        default_delivery_method: string | null,
        default_payment_method: string | null,
        order_confirmation_emails: string[],
    },
    enabled?: {
        isInactiveCustomersEnabled: boolean,
        enabled: boolean,
        offset: number
    },
    residioProPerks: {
        value: string
        visible: boolean
    },
    shareCart: {
        contacts: {
            name: string
            contact: string
        }[]
    }
}

interface SaveCustomerPreferencesInterface {
    saveCustomerPreferences: CustomerPreferencesInterface
}

export interface CustomerMetaPreferences {
    checkout?: {
        delivery_method_list: IMethodsType[],
        payment_method_list: IPaymentMethodsType[]
    }
}

export interface GraphQlPreferences {
    preferences: {
        data?: CustomerPreferencesInterface,
        meta?: CustomerMetaPreferences
    }
}

export interface PreferencesStateProviderType {
    jsConfig?:
        {
            checkout?:
                {
                    defaultDeliveryMethods?: IMethodsType;
                    defaultPaymentMethods?: IPaymentMethodsType;
                    isConfirmationEmailsEnabled: boolean;
                    confirmationEmails: string;
                }
        };
    currentShippingMethodCode?: string;
    setCurrentShippingMethodCode?: Dispatch<SetStateAction<string>>;
    sendWebOrderEmail?: boolean;
    setSendWebOrderEmail?: Dispatch<SetStateAction<boolean>>;
    onSubmit: any;
    form: any;
    query: any;
    mutation: any;
    preferences: any;
    isLoading: boolean;
    isSuccess: boolean;
    isEditMode: boolean;
    onEditClick: (cancel: boolean) => void
}

const PreferencesStateProvider = createContext<PreferencesStateProviderType>(null)

export type PreferencesProviderProps = {
    jsConfig?: {
        checkout?: {
            defaultDeliveryMethods?: IMethodsType;
            defaultPaymentMethods?: IPaymentMethodsType;
            isConfirmationEmailsEnabled: boolean;
            confirmationEmails: string;
        }
    };
} & React.PropsWithChildren<Record<string, unknown>>;

export interface PreferencesFormData {
    shareCart: {
        contacts: ShareCartRecipientData[]
    }
}

interface ShareCartRecipientData {
    name: string
    contact: string
}

export const PreferencesProvider = ({ children, jsConfig }: PreferencesProviderProps) => {

    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    const { yup, resolver } = useYup()
    const { formatMessage } = useIntl()
    const customerData = useCustomer()
    const queryClient = useQueryClient()
    const [queryData, setQueryData] = useState<GraphQlPreferences>()

    const validationSchema = yup.object({
        checkout: yup.object({
            order_confirmation_emails: yup.array().of(yup.string().email())
        }),
        shareCart: yup.object({
            contacts: yup.array().of(
                yup.object({
                    name: yup.string().required(),
                    contact: yup.string().required()
                        .test('emailOrPhone', formatMessage(intl.shareCartContactErrorMessage), value => {
                            return yup.string().email().isValidSync(value) || yup.string().phone().isValidSync(value)
                        })
                })
            )
        }),
        inactiveCustomers: yup.object({
            offset: queryData?.preferences?.data?.inactiveCustomers?.enabled ? yup.number().nan().required().range(0, 60) : null
        }),
        residioProPerks: yup.object({
            value: queryData?.preferences?.data?.residioProPerks?.visible ? yup.string().rangeLength(7, 11) : null
        })
    })

    const [currentShippingMethodCode, setCurrentShippingMethodCode] = useState<string>(null)
    const [sendWebOrderEmail, setSendWebOrderEmail] = useState<boolean>(false)
    const form = useForm<PreferencesFormData>({ resolver: resolver(validationSchema) })

    const query = useQuery<GraphQl<GraphQlPreferences>, Error>({
        queryKey: ['customerPreferences'],
        queryFn: async () => {
            return request<GraphQlPreferences>(preferencesQuery.default)
        }
    })

    useEffect(() => {
        if(query.isSuccess){
            setQueryData(query.data?.data)
            form.reset(query.data.data.preferences.data)
        }
    }, [JSON.stringify(query?.data)])

    const mutation = useMutation<GraphQlResponse<SaveCustomerPreferencesInterface>, Error>(async (formData) => {
        return request(savePreferencesQuery.default, { preferences: formData })
    }, {
        onMutate: (data) => {
            // @ts-ignore
            return customerData.setData({ preferences: data })
        },
        onSuccess: (response) => {
            const errors = response.errors
            const data = response?.data?.saveCustomerPreferences

            if (errors && !!errors.length) {
                toast(errors[0].message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.ERROR
                })
            } else {
                toast('Preferences have been successfully saved', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.SUCCESS
                })
            }

            data && queryClient.setQueryData<GraphQlResponse<GraphQlPreferences>>(
                ['customerPreferences'],
                (oldData) => oldData
                    ? { data: { preferences: { ...oldData.data.preferences, data } } }
                    : oldData
            )
            queryClient.invalidateQueries({ queryKey: ['customerPreferences'] })
        },
        onError: (error, data, context) => {
            // @ts-ignore
            customerData.setData({ preferences: context.previousData }, false)

            toast(
                error.message,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.ERROR
                })
        },
        onSettled: async () => {
            await customerData.invalidateData()
        }
    });

    const onEditClick = (cancel:boolean) => {
        if (cancel) {
            setIsEditMode(false)
            form.reset(query.data.data.preferences.data)
            return;
        }

        setIsEditMode(!isEditMode)
    }

    const onSubmit = (data): void => {
        if (!mutation.isLoading) {
            mutation.mutate(data)
        }
    }
    useEffect(() => {
        if(!mutation.isLoading && mutation.isSuccess) {
            setIsEditMode(!isEditMode);
        }
    }, [mutation.isLoading, mutation.isSuccess]);

    const { preferences = {} }: GraphQlPreferences = query.data?.data || {} as GraphQlPreferences

    return (
        <PreferencesStateProvider.Provider value={{
            jsConfig,
            currentShippingMethodCode,
            setCurrentShippingMethodCode,
            sendWebOrderEmail,
            setSendWebOrderEmail,
            onSubmit,
            form,
            query,
            mutation,
            preferences,
            isLoading: mutation.isLoading || !isEditMode,
            isSuccess: mutation.isSuccess,
            isEditMode,
            onEditClick
        }}
        >
            {children}
        </PreferencesStateProvider.Provider>
    )
}

export const usePreferencesState = () => {
    const context = useContext(PreferencesStateProvider)

    if (!context) {
        console.warn(`usePreferencesState must be used within the PreferencesProvider`)
    }
    return context
}
